















import { defineComponent } from '@vue/composition-api'

import PageView from '@/components/templates/h/common/area/PageView.vue'
import SettingImportModal from '@/components/templates/h/SettingImportModal.vue'
import { AreaOutputCondition } from '@/types/entities'
import {
  TemplateFile,
  ResourceType,
  Where,
  WhereOp,
  OrderBy,
  OrderByDirection,
  OrderByNullsOption,
} from '@/types/generated/graphql'
import { OUTPUT_COND_DELETE, EXPORT_FILENAME } from '@/utils/constant'
import importExportHelper from '@/utils/importExportHelper'
import { setWhereValue, setWhereValues } from '@/utils/searchConditionDef'
const DEFAULT_OUTPUT_CONST = {
  delOutput: [],
}
export default defineComponent({
  components: { PageView, SettingImportModal },
  layout: 'sidebar',
  created() {
    this.helper = new importExportHelper(this)
  },
  data() {
    return {
      resourceType: ResourceType.UiPArea,
      templateFile: TemplateFile.UiPArea_01,
      outputCondition: DEFAULT_OUTPUT_CONST,
      outputUseList: [
        {
          outputCondType: 'Delete',
          displayItems: [OUTPUT_COND_DELETE.includeDelete],
          defaultSelected: DEFAULT_OUTPUT_CONST.delOutput,
          disabledItems: [],
        },
      ],
    }
  },
  methods: {
    changeOutputCondition(cond: AreaOutputCondition) {
      this.outputCondition = cond
    },
    /*********************************
     * 以下はインポートリクエスト用の処理
     *********************************/
    requestImport() {
      console.log('requestImport')
      this.$refs.settingImportModal.show()
    },
    /**
     * インポート開始処理
     */
    async importFileUpload(files: File[]) {
      console.log('importFileUpload', { files })
      this.helper.import({ files: files, resourceType: this.resourceType })
    },
    /**
     * エクスポート処理
     */
    async requestExport() {
      const query = this._createQueryString()
      await this.helper.export({
        query,
        jmespathQuery:
          'data.vUiPAreas.edges[].node | map(&merge(`{"__update": null}`, @), @)',
        templateFile: this.templateFile,
        filenameExport: EXPORT_FILENAME.area,
      })
    },
    /**
     * ファイル抽出Queryを構築する
     */
    _createQueryString(): string {
      const where = this._createWhere()
      const orderBy = this._createOrderByStatement()
      const rowQuery = `
        query c {
          vUiPAreas(
            where: ${this.helper.generateQueryWhereString(where)}
            orderBy: ${this.helper.generateQueryOrderByString(orderBy)}
          ) {
            edges {
              node {
                inactiveFlg
                areaCd
                areaName
                areaType
                configuredFlg
                lastModifiedDatetime
                lastModifiedUserName
              }
            }
          }
        }
        `
      return this.helper.generateQueryString({
        rowQuery: rowQuery,
        resourceType: this.resourceType,
        where: where,
      })
    },
    /**
     * ファイル抽出QueryのOrderByパラメータを構築する
     */
    _createOrderByStatement(): OrderBy[] {
      return [
        {
          field: 'areaCd',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
      ]
    },
    /**
     * ファイル抽出QueryのWhereパラメータを構築する
     */
    _createWhere() {
      const ITEM_SEARCH_CONDITION: Where[] = [
        // 出力条件
        { field: ['inactiveFlg'], op: WhereOp.In, value: [''] },
      ]
      const where = JSON.parse(JSON.stringify(ITEM_SEARCH_CONDITION))

      setWhereValue(where, 'inactiveFlg', [
        '0', // 削除されていないデータは必ず出力する
        ...this.outputCondition.delOutput,
      ])
      console.log({ where })
      return where
    },
  },
})
